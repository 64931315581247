<template>
<v-card elevation="0">
    <v-card-text>
    <v-data-table :headers="headers"
        :items="campaigns"
        sort-by="name"
        class="elevation-1"
    >
        <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Voucher Campaigns</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field v-model="keyword" placeholder="Cari..." hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog scrollable v-model="dialog" max-width="900px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                        New Campaign
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text>
                      <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-autocomplete outlined dense :items="vouchers" item-value="code"
                                :item-text="(row) => {return row.code+ ' - ' + row.voucher_desc;}"
                                v-model="editedItem.voucher_code" label="Voucher" hide-details="auto">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                              <v-menu v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field outlined dense v-model="editedItem.start_date" label="Tanggal Mulai" prepend-icon="mdi-calendar"
                                    readonly v-bind="attrs" v-on="on" hide-details="auto"></v-text-field>
                                </template>
                                <v-date-picker v-model="editedItem.start_date" @input="menuStartDate = false"></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                              <v-menu v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field outlined dense v-model="editedItem.end_date" label="Tanggal Selesai" prepend-icon="mdi-calendar"
                                    readonly v-bind="attrs" v-on="on" hide-details="auto"></v-text-field>
                                </template>
                                <v-date-picker v-model="editedItem.end_date" @input="menuEndDate = false"></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <span class="">Pemberian Voucher Pada</span>
                              <v-radio-group v-model="editedItem.voucher_apply" row dense hide-details="auto">
                                <v-radio label="Semua Spot" value="all"></v-radio>
                                <v-radio label="Kategori Tertentu" value="category"></v-radio>
                                <v-radio label="Spot Tertentu" value="activity"></v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-show="editedItem.voucher_apply=='activity'">
                                <v-autocomplete outlined dense :items="activities" item-text="name" item-value="_id"
                                v-model="editedItem.voucher_apply_id" label="Spot" hide-details="auto"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-show="editedItem.voucher_apply=='category'">
                                <v-autocomplete outlined dense :items="categories" item-text="name" item-value="code"
                                v-model="editedItem.voucher_apply_id" label="Kategori" hide-details="auto"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field outlined dense v-model.number="editedItem.trx_minimum_purchase" 
                                label="Pembelian Minimum Untuk Mendapatkan Voucher" hide-details="auto" type="number" 
                                prefix="Rp" @wheel="$event.target.blur()"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field outlined dense v-model="editedItem.display_message" 
                                label="Display Message" hide-details="auto"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                            Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <!-- <v-icon small @click="deleteItem(item)">
                mdi-delete
            </v-icon> -->
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
</v-card>
</template>

<script>
  export default {
    components: {
    },
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'ID', align: 'ID', sortable: false, value: '_id',
        },
        { text: 'Voucher', value: 'voucher_code' },
        { text: 'Start', value: 'start_date' },
        { text: 'End', value: 'end_date' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        voucher_code: '',
        start_date: '',
        end_date: '',
        voucher_apply: '',
        voucher_apply_id: '',
        trx_minimum_purchase: '',
      },
      defaultItem: {
        voucher_code: '',
        start_date: '',
        end_date: '',
        voucher_apply: '',
        voucher_apply_id: '',
        trx_minimum_purchase: '',
      },
      campaigns: [],
      vouchers: [],
      keyword: '',
      menuStartDate: false,
      menuEndDate: false
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Campaign' : 'Edit Campaign'
      },
      activities () {
        return this.$store.state.activity.objs
      },
      categories () {
        return this.$store.state.category.objs
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      keyword(val) {
        if (!val) val = ''
        // this.campaigns = this.campaigns.filter((el) => {
        //   return el.voucher_desc.toLowerCase().includes(val.toLowerCase()) ||
        //       el.voucher_code.toLowerCase().includes(val.toLowerCase()) ||
        //       el.voucher_mode.toLowerCase().includes(val.toLowerCase())
        // })
      }

    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.$store.dispatch('voucher/getObjs', 'vouchercampaigns').then(response => {
            this.campaigns = response
        })
        this.$store.dispatch('voucher/getObjs', 'vouchers').then(response => {
            this.vouchers = response
        })
        this.$store.dispatch('activity/getObjs')
        this.$store.dispatch('category/getObjs')
      },
      onChangeAmount() {
        this.editedItem.percentage = ''
        this.editedItem.max_amount = ''
      },
      onChangePercentage() {
        this.editedItem.amount = ''
      },
      validateCode(val) {
        this.editedItem.code = val.replace(/\s/g,'_')
      },

      editItem (item) {
        this.editedIndex = this.campaigns.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.campaigns.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.campaigns.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.campaigns[this.editedIndex], this.editedItem)
        } else {
          this.campaigns.push(this.editedItem)
        }

        console.log(this.editedItem);
        
        this.$store.dispatch('voucher/postObj', {pload:this.editedItem, module:'vouchercampaigns'}).then(response => {
            console.log(response)
            this.$store.dispatch('voucher/getObjs', 'vouchercampaigns').then(response => {
                this.campaigns = response
            })

            this.close()
        }, err => {
            console.log(err)
            this.close()
        })
      },
    },
  }
</script>

<style>
.tiptap-vuetify-editor__content {
  max-height: 150px;
}

.tiptap-vuetify-editor__content p {
    margin-top: 1px!important;
    margin-bottom: 1px!important;
    min-height: 1rem;
}
</style>